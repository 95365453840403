import * as validators from '@vuelidate/validators';

export const useI18nValidators = () => {

    const { $i18n } = useNuxtApp();
    const { createI18nMessage } = validators
    
    const messagePath = ({ $validator }: { $validator: string}) => `forms.validations.${$validator}`;
    const messageParams = (params) => ({
        ...params,
        _field_: params._field_ ? $i18n.t(`fields.${params._field_}`) : $i18n.t(`fields.${params.property}`),
    });
    
    const withI18nMessage = createI18nMessage({ t: $i18n.t.bind($i18n), messagePath, messageParams });
    
    // const required = withI18nMessage(validators.required) // DEFAULT VALIDATION
    const required = withI18nMessage(validators.required, { messagePath: () => 'forms.validations.required' }) // OVERRIDE VALIDATION
    const email = withI18nMessage(validators.email, { messagePath: () => 'forms.validations.email' })
    const integer = withI18nMessage(validators.integer, { messagePath: () => 'forms.validations.integer' })
    const numeric = withI18nMessage(validators.numeric, { messagePath: () => 'forms.validations.numeric' })
    const minLength = withI18nMessage(validators.minLength, { messagePath: () => 'forms.validations.minLength', messageParams: (params) => ({...params}) })
    const maxLength = withI18nMessage(validators.maxLength, { messagePath: () => 'forms.validations.maxLength', messageParams: (params) => {console.log('params', params); return ({...params})} }) //`Must be at least ${$params.txtMinLen.min} characters.`
    // const maxLength = withI18nMessage(validators.minLength, { messagePath: () => 'forms.validations.maxLength' })

    // const minLength = (min: number) => validators.helpers.withMessage(
    //     ({ $params}) => `Phone number should contain ${$params.min} digitss.`, minLength(min)
    // )
    
    return {
        required,
        integer,
        numeric,
        email,
        minLength,
        maxLength,
    }
};